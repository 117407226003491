import React from "react";
import { useState, useMemo, useEffect, useRef } from "react";
import { ShimmerTable } from "react-shimmer-effects";
import CustomTable from "../CustomTable";
import FormModal from "../FormModal";
import Modal from "../Modal";
import { Form, Tabs, Tab } from "react-bootstrap";
import titleCase from "../utils/SnakeToTitleCase";

const CoveredEntityAttestations = (props) => {
  const [data, setData] = useState(useMemo(() => props.attestations, []));
  const [underReview, setUnderReview] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [showModalSubmitting, setShowModalSubmitting] = useState(false);
  const [showConfirmSubmission, setShowConfirmSubmission] = useState(false);

  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        try {
          const response = await fetch(props.get_attestations_path, { headers: { Accept: "application/json" } });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const fetchedData = await response.json();
          setData(fetchedData);
        } catch (error) {
          console.log("Error fetching data: ", error);
        } finally {
          setIsLoading(false);
        }
      };
      if (!data || data.length === 0) {
        fetchData();
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  const [modalId, setModalId] = useState(null);
  const [showModalComplete, setShowModalComplete] = useState(false);
  const [showModalDenied, setShowModalDenied] = useState(false);
  const [showModalArchive, setShowModalArchive] = useState(false);
  const [showModalNote, setShowModalNote] = useState(false);

  const manufacturerName = titleCase(props.manufacturer);

  const [formState, setFormState] = useState({});

  const handleChange = (rowId, value) => {
    setFormState(prevState => {
      const newState = { ...prevState, [rowId]: value };
      if (newState[rowId] == "deny") {
        setModalId(rowId);
        setShowModalDenied(true);
      } else if (newState[rowId] == "archive") {
        setModalId(rowId);
        setShowModalArchive(true);
      } else if (newState[rowId] == "note") {
        setModalId(rowId);
        setShowModalNote(true);
      } else if (value == "") {
        resetAttestation(rowId);
      } else {
        completeAttestation(rowId);
      }
      return newState;
    });
  };

  const resetAttestation = (rowId) => {
    const element = document.getElementById(`completed_attestation_${rowId}`);
    element.classList.add('d-none');
    if (formState.length == 0) {
      setReadyToSubmit(false);
    }
  }

  const completeAttestation = (rowId) => {
    // Get the element
    const check_element = document.getElementById(`completed_attestation_${rowId}`);
    const denial_reason_element = document.getElementById(`attestation_denial_reason_${rowId}`);

    if (formState[rowId] == "deny") {
      const denial_reason = document.getElementById(`attestation_denial_reason`).value;
      
      setFormState(prevState => {
        const newState = { ...prevState, [`denial_reason_${rowId}`]: denial_reason };
        return newState;
      });
      if ( Boolean(denial_reason) ) {
        denial_reason_element.innerHTML = denial_reason;
        denial_reason_element.classList.remove('d-none');
        if (check_element && check_element.classList.contains('d-none')) {
          check_element.classList.remove('d-none');
        }
      } else {
        denial_reason_element.innerHTML = "You must enter a denial reason.";
        denial_reason_element.classList.remove('d-none');
        if (check_element && !check_element.classList.contains('d-none')) {
          check_element.classList.add('d-none');
        }
      }
    } else if (formState[rowId] == "archive") {
      const archive_jira_element = document.getElementById(`attestation_archive_jira_${rowId}`);
      const archive_jira = document.getElementById("attestation_archive_jira").value;
      
      setFormState(prevState => {
        const newState = { ...prevState, [`archive_jira_${rowId}`]: archive_jira };
        return newState;
      });
      archive_jira_element.classList.remove('d-none');

      const attestation_status = document.getElementById(`attestation_status_${rowId}`);
      attestation_status.classList.add('d-none');

      archive_jira_element.innerHTML = `Archived via ${archive_jira || "ENTER JIRA"}`;
      const archive_button = document.getElementById(`archive_button_${rowId}`);
      archive_button.classList.add('d-none');
      if (check_element && check_element.classList.contains('d-none')) {
        check_element.classList.remove('d-none');
      }
    } else if (formState[rowId] == "note") {
      const note_element = document.getElementById(`attestation_note_${rowId}`);
      const note = document.getElementById("attestation_note").value;

      setFormState(prevState => {
        const newState = { ...prevState, [`note_${rowId}`]: note };
        return newState;
      });
      note_element.classList.remove('d-none');

      note_element.innerHTML = note;
      if (check_element && check_element.classList.contains('d-none')) {
        check_element.classList.remove('d-none');
      }
    } else {
      setFormState(prevState => {
        const newState = { ...prevState, [`denial_reason_${rowId}`]: null };
        return newState;
      });
      denial_reason_element.innerHTML = "";
      denial_reason_element.classList.add('d-none');
      if (check_element && check_element.classList.contains('d-none')) {
        check_element.classList.remove('d-none');
      }  
    }
    setReadyToSubmit(true);
  }

  const handleSubmit = async (event) => {
    setShowModalSubmitting(true);
    setIsLoading(true);    
    // Define your endpoint
    const endpoint = '/attestations/bulk_update';
  
    try {
      // Wrap formState in an "attestations" object
      const requestBody = {
        attestations: formState
      };
  
      // Send requestBody to your server
      const response = await fetch(endpoint, {
        method: 'POST', // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        body: JSON.stringify(requestBody),
      });
  
      // Check if the request was successful
      if (response.ok) {
        console.log('Data sent successfully');
      } else {
        console.log('Error: ', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
    location.reload();
  };
  
  const columns = useMemo(
    () => [  
      {
        Header: "Pharmacy",
        accessor: "pharmacy_id",
        filter: "fuzzyText",
        Cell: (row) => <>
          <b>{row.row.original.contracted_pharmacy.pharmacy_name}</b><br/>        
          <div className="badge bg-white text-muted border me-2">
            PID: {row.value}
          </div>
          <div className="badge bg-secondary me-2">
            NPI: {(row.row.original.contracted_pharmacy.npi || [])[0]}
          </div>
          { !row.row.original.pharmacy_agreement_json && row.row.original.status != "not_started" && row.row.original.type == "StateAttestation" ?
            <div className="badge bg-white text-muted me-2 border">
              ATTESTATION
            </div>
          : null }
        </>
      },
      { 
        Header: "Due Date",
        accessor: "due_date",
        filter: "fuzzyText"        
      },
      {
        Header: "Status",
        accessor: "status",
        filter: "fuzzyText",
        Cell: (row) => (
          <div className={`badge text-uppercase ${row.value == "complete" ? "bg-success" : row.value == "denied" ? "bg-danger" : "bg-white text-muted border"}`}>
            {row.value == "complete" ? "Approved" : titleCase(row.value) }
          </div>
        )
      },
      {
        Header: "Pharmacy Agreement",
        accessor: "pharmacy_agreement_json",
        Filter: () => {},
        Cell: (row) => (
          <div className="dropdown ms-1">
          <div className="btn-group btn-group-sm">
            { row.value ?
              <a className="btn btn-primary btn-sm" href={row.row.original.pharmacy_agreement_json.path}>
                <i className="fa fa-download me-2"></i>
                ({row.row.original.pharmacy_agreement_json.extension})
              </a>
            :
            <small className="text-muted">
              --
            </small>
            }
          </div>
        </div>  
        ),
      },
      {
        Header: "Select approval status:",
        Cell: (row) => (
          <>
            { row.row.original.status == "in_review" && !row.row.original.deleted_at ?
              <>
              <div className="input-group" role="group" id={`attestation_status_${row.row.original.id}`}>
                <select
                  name={`approval[${row.row.original.id}]`}
                  value={formState[row.row.original.id]}
                  className="form-select form-select-sm"
                  onChange={(event) => handleChange(row.row.original.id, event.target.value)}
                >
                  <option value="" selected={formState[row.row.original.id] == "" ? "selected" : null}>{"< Make A Selection >"}</option>
                  <option value="approve" selected={formState[row.row.original.id] == "approve" || row.row.original.status == "complete" ? "selected" : null}>Approve</option>
                  <option value="deny" selected={formState[row.row.original.id] == "deny" || row.row.original.status == "denied" ? "selected" : null}>Deny</option>
                  <option value="note">+ Add Note</option>
                </select>
                <div className={`input-group-text ${row.row.original.status == "in_review" ? 'd-none' : null} bg-success`} id={`completed_attestation_${row.row.original.id}`}>
                  <i className="fa fa-check text-white"></i>
                </div>
              </div>
            </>
          : row.row.original.status != "not_started" && !row.row.original.deleted_at ?
          <>
            <small className="d-flex justify-content-between">           
              <span id={`attestation_status_${row.row.original.id}`} className={`badge border bg-white p-2 text-uppercase ${row.row.original.status == "denied" ? "text-danger" : row.row.original.status == "complete" ? "text-success" : "text-muted"}`}>
                {titleCase(row.row.original.status == "complete" ? `approved ${row.row.original.approved_at || ''}` : row.row.original.status)}
                <i className="fa fa-check ms-2"></i>
              </span>
            </small>
          </>
          : row.row.original.status == "in_progress" && !row.row.original.deleted_at ?
            <label
              className="btn btn-sm bg-white text-muted border"
              onClick={() => handleChange(row.row.original.id, "note")}
            >
              + Add Note
            </label>
          : <small className="text-muted">--</small>
          }
          <div 
            id={`attestation_denial_reason_${row.row.original.id}`} 
            className={`smaller alert alert-danger alert-sm mb-0 mt-2 fs-9 p-2${row.row.original.status != "denied" ? ' d-none' : null}`} 
            role="button"
            onClick={() => { handleChange(row.row.original.id, "deny") }}
          >
            {row.row.original.denial_reason}
          </div>
          <div 
              id={`attestation_note_${row.row.original.id}`} 
              className={`smaller alert alert-info alert-sm mb-0 mt-2 fs-9 p-2${!row.row.original.note ? ' d-none' : null}`} 
              role="button"
              onClick={() => { handleChange(row.row.original.id, "note") }}
            >
              {row.row.original.note}
          </div>          
          <span 
            id={`attestation_archive_jira_${row.row.original.id}`} 
            className="badge bg-warning d-none"
            role="button"
            onClick={() => { handleChange(row.row.original.id, "archive") }}>
          </span>
          </>
        ),
      },
      {
        Header: "Archive",
        Cell: (row) => (
          <center>
            { row.row.original.deleted_at ?
              <small>
                <i className="text-danger">Archived</i>
              </small>                
            : row.row.original.status != "not_started" ?
              <div 
              className="btn btn-sm btn-danger"
              id={`archive_button_${row.row.original.id}`}
              onClick={(event) => handleChange(row.row.original.id, "archive")}>
                <i className="fa fa-remove"></i>
              </div>
            : <small className="text-muted">--</small> }
          </center>
        )        
      }
    ],
    []
  );

  const ConfirmationPrompt = () => {
    return (
      <>
        <p>Are you sure you wish to submit these changes?</p>
        <ul className="list-group">
          {Object.entries(formState).map(([key, value]) => {
            const record = ([...data.in_review, ...data.complete, ...data.denied]).find(item => item.id == key);
            return record ? (
              <li className="list-group-item" key={key}>{record.pharmacy_id} - {record.contracted_pharmacy.pharmacy_name} {">"} Status: {value}</li>
            ) : (
              null
            );
          })}
        </ul>
      </>
    );
  };
  
  const AttestationPrompt = (prompt_props) => {
    const innerRef = useRef();
  
    useEffect(() => {
        if (innerRef.current) {
          innerRef.current.focus();
        }
      }, [prompt_props.role]);
      
    return (      
      <React.Fragment>
        <div className="bg-body-tertiary p-4 rounded-4 shadow-sm border">
          <div className="row">
            <div className="col-sm-6">
              <small>
                340B ID:<br/>
                <b>{prompt_props.attestation.id_340b}</b>
              </small>
            </div>
            <div className="col-sm-6">
              <small>
                Due Date:<br/>
                <b>{prompt_props.attestation.due_date}</b>
              </small>                
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-6">
              <small>
                Manufacturer:<br/>
                <b>{titleCase(prompt_props.attestation.manufacturer)}</b>
              </small>
            </div>
            <div className="col-sm-6">
              <small>
                Pharmacy ID:<br/>
                <b>{prompt_props.attestation.pharmacy_id}</b>
              </small>                
            </div>
          </div>
        </div>
        <div className="mt-4">
          { prompt_props.role == "deny" ?
            <Form.Group className="mb-3" controlId="denial_reason">
              <p>Please confirm your reason to <b className="text-danger">Deny</b> this attestation.</p>
              <div className="bg-body-tertiary rounded-4 p-4 border">
                <Form.Label><b className="text-danger">Denial Reason:</b> <small className="text-muted">(required)</small></Form.Label>
                <Form.Control 
                  _ref={innerRef}
                  required as="textarea" 
                  name="attestation[denial_reason]" 
                  placeholder="Enter your denial reason..." 
                  id="attestation_denial_reason" />
              </div>
            </Form.Group>
          : prompt_props.role == "archive" ?
            <Form.Group className="mb-3" controlId="archive_jira">
            <p>Please enter the JIRA ticket number associated with the request to archive this attestation.</p>
            <div className="bg-body-tertiary rounded-4 p-4 border">
              <Form.Label><b className="text-danger">JIRA Ticket Number:</b> <small className="text-muted">(required)</small></Form.Label>
              <Form.Control required placeholder="Enter JIRA ticket..." id="attestation_archive_jira" />
            </div>
          </Form.Group>
          : prompt_props.role === "note" ?
            <Form.Group className="mb-3" controlId="attestation_note">
              { prompt_props.attestation.notes.length > 0 ?
                <ul className="list-group mb-3">
                  {prompt_props.attestation.notes.map((note) => {
                    return (<li className="list-group-item">
                      <small className="text-muted">{note.content}</small>
                    </li>)
                  })}
                </ul>
              : null }
              <div className="bg-body-tertiary rounded-4 p-4 border">
                <Form.Label>Note:</Form.Label>
                <Form.Control
                  _ref={innerRef}
                  required
                  as="textarea"
                  placeholder="Enter Note..."
                  id="attestation_note"
                />
              </div>
            </Form.Group>
          :
          <p>Please confirm that you wish to <b className="text-success">ACCEPT</b> this attestation.</p>
          }
        </div>
      </React.Fragment>
    );
  };

  const defaultTab = () => {
    if (data.in_review.length > 0) {
      return "in_review";
    } else if (data.complete.length > 0) {
      return "complete";
    } else if (data.denied.length > 0) {
      return "denied";
    } else if (data.archived.length > 0) {
      return "archived";
    } else if (data.archived.length > 0) {
      return "not_started";
    }
  }

  return (
    <React.Fragment>
      {isLoading ?
        <ShimmerTable row={5} col={5} />
      : <>
        <div className="row mb-3">
          <div className="col-3">
            <h6>Manufacturer:</h6>
            <h3 className="mb-3 text-muted">
              <i className="fa fa-industry me-2"></i>
              {manufacturerName}
            </h3>
          </div>
          <div className="col-3">
            <h6>Policy:</h6>
            <a className="mb-3 text-muted" onClick={() => setUnderReview(true)}>
              <b>{props.policy_name}</b>
            </a>
          </div>
          <div className="col-3">
            <h6>Pharmacy Agreements:</h6>
            <a href={props.download_pdfs_link} className="btn btn-primary btn-sm">
              <i className="fa fa-download me-2"></i>
              Download (.zip)
            </a>
          </div>
          <div className="col-3">
            <h6>CSV Export:</h6>
            <a href={props.export_csv_link} className="btn bg-white border btn-sm">
              <i className="fa fa-download me-2"></i>
              Download (.csv)
            </a>
          </div>
        </div>
          { !underReview ?
            <div className="btn btn-primary btn-lg d-block" onClick={() => setUnderReview(true)}>
              Begin Review
            </div>
          :
            <>
            <Tabs defaultActiveKey={props.tab || defaultTab} id="attestation_tabs" className="">
              {(data.in_review.length > 0) ? (
                <Tab
                  eventKey="in_review"
                  className="bg-white p-4 pb-1 border border-top-0 rounded-bottom"
                  title={
                    <>
                      <i className="fa fa-search"></i> In Review <span className="badge bg-warning">{data.in_review.length}</span>
                    </>
                  }>
                  <p className="text-muted">
                    Select approval status for each applicable line item. <b className="text-danger">When finished, click "Submit Attestation(s)" at the bottom to save all of your selections.</b>
                  </p>
                  <CustomTable columns={columns} data={data.in_review} page_size={100} hide_page_size_if_unneeded={true} /> 
                  { readyToSubmit ? 
                    <a type="submit" className="btn btn-success btn-lg d-block" onClick={() => setShowConfirmSubmission(true)}>
                      Submit Attestation(s)
                    </a> 
                  : null }
                </Tab>
                )
              : null}
              {(data.complete.length > 0) ? (
                <Tab
                  eventKey="complete"
                  className="bg-white p-4 pb-1 border border-top-0 rounded-bottom"
                  title={
                    <>
                      <i className="fa fa-check"></i> Approved <span className="badge bg-success">{data.complete.length}</span>
                    </>
                  }>
                  <CustomTable columns={columns} data={data.complete} page_size={100} hide_page_size_if_unneeded={true} /> 
                  { readyToSubmit ? 
                    <a type="submit" className="btn btn-success btn-lg d-block" onClick={() => setShowConfirmSubmission(true)}>
                      Submit Attestation(s)
                    </a> 
                  : null }
                </Tab>
              )
              : null}
              {(data.denied.length > 0) ? (
                <Tab
                  eventKey="denied"
                  className="bg-white p-4 pb-1 border border-top-0 rounded-bottom"
                  title={
                    <>
                      Denied <span className="badge bg-danger">{data.denied.length}</span>
                    </>
                  }>
                    <CustomTable columns={columns} data={data.denied} page_size={100} hide_page_size_if_unneeded={true} /> 
                    { readyToSubmit ?
                    <a type="submit" className="btn btn-success btn-lg d-block" onClick={() => setShowConfirmSubmission(true)}>
                      Submit Attestation(s)
                    </a>
                  : null }
                </Tab>
              )
              : null}
              {(data.not_started.length > 0) ? (
                <Tab
                  eventKey="no_started"
                  className="bg-white p-4 pb-1 border border-top-0 rounded-bottom"
                  title={
                    <>
                      Not Started <span className="badge bg-primary">{data.not_started.length}</span>
                    </>
                  }>
                  <CustomTable columns={columns} data={data.not_started} page_size={100} hide_page_size_if_unneeded={true} /> 
                </Tab>
              )
              : null}
              {(data.archived.length > 0) ? (
                <Tab
                  eventKey="archived"
                  className="bg-white p-4 pb-1 border border-top-0 rounded-bottom"
                  title={
                    <>
                      Archived <span className="badge bg-secondary">{data.archived.length}</span>
                    </>
                  }>
                  <CustomTable columns={columns} data={data.archived} page_size={100} hide_page_size_if_unneeded={true} />
                </Tab>              
              )
              : null}              
            </Tabs>
            </>
          }
          <FormModal
              show={showModalComplete}
              title="Complete Attestation"
              buttonText="Complete Attestation"
              buttonType="success"
              modalId={`complete_attestation_modal_${modalId}`}
              fields={<AttestationPrompt attestation={data.in_review.find((row) => row.id == modalId)} role="complete" />}
              method="POST"
              action={`/attestations/${data.in_review.find((row) => row.id == modalId)?.id}/complete`}
              dismiss={() => {
                setShowModalComplete(false);
              }}
            />
            <Modal
              show={showModalDenied}
              title="Deny Attestation"
              buttonText="Deny Attestation"
              buttonType="danger"
              modalId={`deny_attestation_modal${modalId}`}
              body={<AttestationPrompt attestation={data.in_review.find((row) => row.id == modalId)} role="deny" />}
              action={() => {completeAttestation(modalId);setShowModalDenied(false);}}
              dismiss={() => {
                setShowModalDenied(false);
              }}
            />
            <Modal
              show={showModalArchive}
              title="Archive Attestation"
              buttonText="Archive On Submit"
              buttonType="danger"
              modalId={`archive_attestation_modal${modalId}`}
              body={<AttestationPrompt attestation={[...data.in_review, ...data.complete, ...data.denied].find((row) => row.id == modalId)} role="archive" />}
              action={() => {
                completeAttestation(modalId);
                setShowModalArchive(false);
              }}
              dismiss={() => {
                setShowModalArchive(false);
              }}
            />
            <Modal
              show={showModalNote}
              title="Add Note"
              buttonText="Add Note"
              buttonType="primary"
              modalId={`note_attestation_modal${modalId}`}
              body={<AttestationPrompt attestation={[...data.in_review, ...data.complete, ...data.denied].find((row) => row.id == modalId)} role="note" />}
              action={() => {
                completeAttestation(modalId);
                setShowModalNote(false);
              }}
              dismiss={() => {
                setShowModalNote(false);
              }}
            />            
            <Modal
              show={showConfirmSubmission}
              title="Submit Attestation Updates"
              buttonText="Submit Attestations"
              buttonType="danger"
              modalId={`confirm_submission_modal${modalId}`}
              body={<ConfirmationPrompt />}
              action={() => {handleSubmit()}}
              dismiss={() => {
                setShowConfirmSubmission(false);
              }}
            />            
        </>
      }     
      <Modal
        show={showModalSubmitting}
        title="Submitting Attestation Updates"
        modalId={`submitting_attestation_modal${modalId}`}
        body="Your updates are being submitted..."
        disableFooter={true}        
      />                     
    </React.Fragment>
  );
};

export default CoveredEntityAttestations;

import React from "react";
import { useState } from "react";
import FormModal from "../FormModal";
import InHouseCertifications from "./InHouseCertifications";

const PharmacyCertification = (props) => {
  const modalId = props.in_house_attestation_id;
  const [showFlagModal, setShowFlagModal] = useState(false);
  const [showResetFlagModal, setShowResetFlagModal] = useState(false);

  return (
    <React.Fragment>
      <div className="jumbotron p-4 mt-3 mb-3 bg-body-tertiary rounded-4">
        <div className="card-group">
          <div className="card">
            <div className="card-body">
              <h6>For Entity</h6>
              <h5 className="text-muted">
                <i className="fa-solid fa-hospital me-2"></i>
                {props.entity_name}
              </h5>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h6>340B ID:</h6>
              <h5 className="text-muted">
                <a href={`/entities/340bid/${props.id_340b}`} className="badge bg-secondary" target="_blank">
                  {props.id_340b}
                </a>
              </h5>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h6>Has In-House Pharmacy?</h6>
              <b>
                {props.has_in_house_flag == true ?
                <>
                  TRUE
                </>
                : props.has_in_house_flag == false ?                
                <>
                  FALSE
                </>
                : "Not yet determined."
                }                
              </b>
              {props.has_in_house_flag != null && props.admin_manager == true ?
                  <div
                    className="btn-xs btn btn-danger ms-2"
                    onClick={() => setShowResetFlagModal(true)}>
                    Reset
                  </div>
              : null }
              <div className="text-muted">
                <small>
                  {props.can_update_flag && props.has_in_house_flag == null ? 
                    "This will update the entity has_inhouse_pharmacy flag." 
                  : props.has_in_house_flag == null ?
                    "Not available until all NPIs have been attested and reviewed."
                  : 
                    "This will be taken into account for 340B pricing."
                  }
                </small>
              </div>
              { props.can_update_flag ? 
              <>
                { props.has_in_house_flag == null ?
                  <a onClick={() => setShowFlagModal(true)} className={`btn mt-2 ${!props.can_update_flag ? 'btn-secondary disabled' : props.in_house_determination == "in_house" ? 'btn-danger' : 'btn-success'}`}>
                    Set Has In-House Flag to {props.in_house_determination == "in_house" ? "TRUE" : "FALSE"}  
                  </a>                  
                : props.has_in_house_flag != (props.in_house_determination == "in_house") ?
                  <a onClick={() => setShowFlagModal(true)} className={`btn mt-2 ${!props.can_update_flag ? 'btn-secondary disabled' : props.in_house_determination == "in_house" ? 'btn-danger' : 'btn-success'}`}>
                    Update Has In-House Flag to {props.in_house_determination == "in_house" ? "TRUE" : "FALSE"}  
                  </a> 
                :                               
                null }
              </>
              : null }
            </div>
          </div>
        </div>
      </div>
      <h3 className="text-title mt-4 mb-3">
        <i className="fa-solid fa-search me-2 ms-2"></i>
        Review & Approval          
      </h3>
      <div className="jumbotron p-4 mt-3 mb-3 bg-body-tertiary rounded-4">
        <InHouseCertifications 
          get_certifications_path={props.get_certifications_path} 
          due_date={props.due_date}
          download_pdfs_link={props.download_pdfs_link}
          export_csv_link={props.export_csv_link}
        />
      </div>
      <FormModal
        show={showFlagModal}
        title='Update Entity "Has In-House Pharmacy?"'
        buttonText={`Yes, set In-House to ${props.in_house_determination == "in_house" ? "TRUE" : "FALSE"}`}
        buttonType={props.in_house_determination == "in_house" ? "danger" : "success"}
        fields={
        <>
          <p>
            This will update the "<b>has_inhouse_pharmacy?</b>" flag value on the <b>covered_entity</b> to <b className="text-uppercase">{props.in_house_determination ? "TRUE" : "FALSE"}</b>.
          </p>
          <p>
            Are you sure you wish to do this?
          </p>
        </>
        }
        modalId={`update_entity_flag_${modalId}`}
        method="POST"
        action={`/pharmacy_certification/${modalId}/update_has_inhouse_pharmacy_flag`}
        dismiss={() => {
          setShowModalFlag(false);
        }}
      />
      <FormModal
        show={showResetFlagModal}
        title='"Has In-House Pharmacy?" Flag Reset'
        buttonText="Yes, reset the flag"
        buttonType="danger"
        fields={
        <>
          <p>
            <b>Resetting this flag will:</b>
          </p>
          <ul className='list-group list-group-flush mb-3'>
            <li className='list-group-item'>Reset the "<b>has_inhouse_pharmacy?</b>" flag value on the covered entity record.</li>
            <li className='list-group-item'>Archive the current In-House Attestation.</li>
            <li className='list-group-item'>Restart the 340B ESP Pharmacy Certification process.</li>
          </ul>
          <p>
            <b>Are you sure you wish to continue?</b>
          </p>
          <p>
            <small className="text-danger">
              <b>Note:</b> No automated emails will be triggered by this action.
            </small>
          </p>
        </>
        }
        modalId={`update_entity_flag_${modalId}`}
        method="POST"
        action={`/pharmacy_certification/${modalId}/reset_has_inhouse_pharmacy_flag`}
        dismiss={() => {
          setShowResetFlagModal(false);
        }}
      />
    </React.Fragment>
  );
};

export default PharmacyCertification;

import React from "react";
import { Button, Form } from "react-bootstrap";
import CreateEffectiveDate from "./CreateEffectiveDate";
import titleCase from "../utils/SnakeToTitleCase";

const EffectiveDateSearch = (props) => {
  return (
    <React.Fragment>
      <div className="pt-3 pb-2 mb-2">
        <h1 className="h2 float-start me-1">
          <i className="fa-regular fa-calendar-check pe-2"></i>
          Effective Dates
          {props.params?.manufacturer ?
            <div className="badge text-muted bg-light ms-2 fw-normal">{titleCase(props.params.manufacturer)}</div>
          : null}
        </h1>
        { props.can_edit ?
          <div className="pt-1">
            <CreateEffectiveDate
              manufacturers={props.manufacturers}
              manufacturer_networks={props.manufacturer_networks}
              id_340b={props.params.id_340b} />
          </div>
        : null }
      </div>
      <div className="clearfix"></div>
      <p className="text-muted">Dates at which designations come into effect for 340B pricing eligibility.</p>
      <div className="jumbotron p-4 mb-2 bg-body-tertiary rounded-4">
        <Form method="GET" action={props.search_path} data-testid="effective-date-search-form">
          <div className="row">
            <Form.Group className="col-sm-4" controlId="id_340b">
              <Form.Label>340B ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter 340B ID..."
                name="id_340b"
                data-testid="id_340b"
                autoFocus={true}
                defaultValue={props.params.id_340b} />
            </Form.Group>
            <Form.Group className="col-4" controlId="manufacturer">
              <Form.Label>Manufacturer</Form.Label>
              <Form.Select type="select" placeholder="Manufacturer" name="manufacturer" data-testid="manufacturer" defaultValue={props.params.manufacturer}>
                <option value="">All Manufacturers</option>
                {props.manufacturers.map((manufacturer) => (
                  <option key={manufacturer.key} value={manufacturer.key}>
                    {manufacturer.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="col-4">
              <br/>
              <Button className="mt-2" variant="primary" type="submit">
                <i className="fa fa-search me-1"></i>
                Search Effective Dates
              </Button>
            </Form.Group>
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default EffectiveDateSearch;
